import React from "react";
import { graphql, Link, useStaticQuery } from "gatsby";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
import Layout from "../components/layout";
import { Button } from "react-bootstrap";

const ThankYouInsurance = () => {
	const data = useStaticQuery(
		graphql`
			query {
				site {
					siteMetadata {
						siteUrl
						company
					}
				}
			}
		`
	);

	const companyName = data.site.siteMetadata.company;
	const title = `Thank you for contacting ${companyName}`;

	return (
		<Layout>
			<GatsbySeo title={title} language="en" noindex nofollow />
			<section style={{ minHeight: "50vh" }}>
				<Container className="h-100">
					<Row className="py-5 py-lg-7 h-100 align-items-center">
						<Col className="text-center">
							<h1 className="pb-3">
								Thank you for contacting{" "}
								<span className="gradient-text">{companyName}</span>
							</h1>
							<p>We will get back to you as soon as possible</p>
							<Button
								size="lg"
								className=" gradient-button px-7 w-100 w-md-auto py-3 mt-4 text-uppercase"
								as={Link}
								to="/"
							>
								Home
							</Button>
						</Col>
					</Row>
				</Container>
			</section>
		</Layout>
	);
};
export default ThankYouInsurance;
